import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.infra.server.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-infra-server-list',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.infra.server.list/view.scss */
.card-header {
  background-color: rgba(73, 196, 155, 0.3);
}

.filter-area {
  height: 40px;
}
.filter-area * {
  line-height: 40px;
}`],
})
export class PortalInfraServerListComponent implements OnInit {
    @Input() isAdmin = false;
    public list = [];
    public filter = {
        onlymine: false,
        status: "run",
    };
    public query: any = {
        current: 1,
        start: 1,
        end: 1,
        text: "",
    };


    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    private async load(page = 1) {
        // if (document.activeElement) document.activeElement.blur();

        const body = JSON.parse(JSON.stringify(this.filter));
        if (this.isAdmin) delete body.status;
        this.query.current = page;
        body.page = page;
        body.text = this.query.text;

        const { code, data } = await wiz.call("load", body);
        if (code !== 200) return;
        const { lastpage, rows } = data;
        this.list = rows.map(it => {
            it.domain = this.domain(it);
            return it;
        });
        this.query.start = Math.floor((page - 1) / 20) * 20 + 1;
        this.query.end = lastpage;
        await this.service.render();
    }

    public domain(item) {
        if (item.domain) {
            let t = item.domain;
            if (!t.startsWith("https://")) t = `https://${t}`;
            return t;
        }
        return `https://${item.name}.nanoha.kr`;
    }

    // modal
    public modal = {
        add: false,
        info: false,
    };
    public info = null;

    public showModal(item = null) {
        if (this.isAdmin) this.modal.add = true;
        else this.modal.info = true;
        this.info = item;
        this.service.render();
    }

    public hideModal() {
        if (this.isAdmin) this.modal.add = false;
        else this.modal.info = false;
        this.info = null;
        this.service.render();
    }
}

export default PortalInfraServerListComponent;